<template>
  <v-container fluid>
    <v-card>
      <v-card-title>スタッフ入店・退店用QRコード</v-card-title>
      <v-card-subtitle>
        スタッフ用の入口・出口にお貼りください。
        <br />スタッフがQRコードをスキャンした時、スタッフの入店・退店時刻が記録されます。また、スタッフの感情指数も記録できます。
      </v-card-subtitle>
      <v-card-text>
        <v-divider></v-divider>
        <v-row justify="center">
          <v-col cols="auto">入店用</v-col>
        </v-row>
        <qr-code-display :url="enterUrl"></qr-code-display>
        <v-divider></v-divider>
        <v-row justify="center">
          <v-col cols="auto">退店用</v-col>
        </v-row>
        <qr-code-display :url="exitUrl"></qr-code-display>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import qrCodeDisplay from '@/components/staff/qrCodeDisplay'
import { EVENT_NAMES, analyticsEvent } from '@/helpers/analytics'

export default {
  components: {
    qrCodeDisplay
  },
  data: () => ({
    enterUrl: '',
    exitUrl: ''
  }),
  async created () {
    const ownerId = this.$route.params.ownerId
    const absoluteUrl = window ? window.location.origin : ''
    this.enterUrl = `${absoluteUrl}/staff-enter/${ownerId}`
    this.exitUrl = `${absoluteUrl}/staff-exit/${ownerId}`
  },
  mounted () {
    analyticsEvent(EVENT_NAMES.LOADED_STAFF_ATTENDANCE)
  }
}
</script>
