<template>
  <v-col>
    <v-row justify="center" v-if="isUrlEmpty">
      <v-col cols="auto" align-self="center">
        <v-progress-circular color="primary" indeterminate></v-progress-circular>
      </v-col>
      <v-col cols="auto" align-self="center">QRコードの作成中...</v-col>
    </v-row>

    <v-row justify="center" v-if="!isUrlEmpty">
      <!-- <v-col cols="auto"> -->
      <VueQr :dotScale=".5" :text="shortUrl" />
      <!-- </v-col> -->
    </v-row>
    <v-row justify="center" v-if="!isUrlEmpty">
      <v-col cols="auto">
        <a :href="shortUrl">{{shortUrl}}</a>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import VueQr from 'vue-qr'
import { shrinkUrl } from '@/helpers/referral'

export default {
  name: 'qr-code-display',
  components: { VueQr },
  props: {
    url: {
      type: String,
      required: true
    }
  },
  data: () => ({
    shortUrl: ''
  }),
  computed: {
    appSociallyColor () {
      return '#FFD54F'
    },
    isUrlEmpty () {
      return this.shorUrl === ''
    }
  },
  async mounted () {
    this.shortUrl = await shrinkUrl(this.url)
  }
}
</script>
